import { Routes, Route } from "react-router-dom";

import CreditReport from "./app/components/credit-report";
import CreditSimulator from "./app/components/credit-simulator";
import Home from "./app/components/home";
import Faq from "./app/components/faq";
import PrivacyPolicy from "./app/components/privacy-policy";
import TermsConditions from "./app/components/terms-conditions";
import Authentications from "./app/components/authentications";
import Success from "./app/components/success";
import TestAuthentications from "./app/components/test/test_authentications";
import TestCreditReport from "./app/components/test/test_creditReport";
import TestCreditSimulator from "./app/components/test/test_simulator";
import Auth from "./app/pages/auth";
import Dashboard from "./app/pages/dashboard";
import { PrivateRoute, PublicRoute } from "./app/guards/authGuard";
import PersonalCredit from "./app/pages/personalCredit";
import PersonalCreditReport from "./app/pages/credit-report";
import CreditSimulatorDashboard from "./app/pages/credit-simulator";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route element={<PublicRoute />}>
        <Route path="/credit-report/:userId" element={<CreditReport />} />
        <Route
          path="/test-credit-report/:userId"
          element={<TestCreditReport />}
        />
        <Route path="/authentication/:userId" element={<Authentications />} />
        <Route
          path="/test-authentication/:userId"
          element={<TestAuthentications />}
        />
        <Route path="/credit-simulator/:userId" element={<CreditSimulator />} />
        <Route
          path="/test-credit-simulator/:userId"
          element={<TestCreditSimulator />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/success" element={<Success />} />
        <Route path="/login" element={<Auth />} />
      </Route>

      {/* Private Routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/personal-credit/:userId" element={<PersonalCredit />} />
        <Route
          path="/credit-simulator-dashboard/:userId"
          element={<CreditSimulatorDashboard />}
        />
      
      </Route>
    </Routes>
  );
};

export default AppRoutes;

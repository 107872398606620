import { useEffect, useState } from "react";
import styles from "../games.module.scss";
import {
  getScramblerGame,
  submitScramblerGame,
} from "../../../../store/reducers/scrambler";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../utils/constants";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Scrambler = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth?.data?.user);
  const [selectedLetters, setSelectedLetters] = useState([]); // Letters selected by the user
  const [availableLetters, setAvailableLetters] = useState([]); // Letters available for selection
  const [letters, setLetters] = useState([]); // Words fetched from the backend
  const [currentWordIndex, setCurrentWordIndex] = useState(0); // Current word being solved
  const [newAvailableLetters, setNewAvailableLetters] = useState([]);
  const [gameFinished, setGameFinished] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(null);
  const [wordsToDisplay, setWordsToDisplay] = useState([]);
  const { width, height } = useWindowSize(); // Get current window dimensions for confetti
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false); // Track if answer is submitted

  useEffect(() => {
    // Fetch the scrambler game letters when the component loads
    dispatch(getScramblerGame()).then((response) => {
      if (response?.meta?.requestStatus === constants.THUNK_STATUS.FULFILLED) {
        setLetters(response.payload.words);
        setAvailableLetters(
          response.payload.words[currentWordIndex].jumble_word.split("")
        );
        setNewAvailableLetters(
          response.payload.words[currentWordIndex].jumble_word.split("")
        );
      }
    });
  }, []);

  // Change the word index every 10 seconds if the user doesn't submit the answer
  useEffect(() => {
    const timer = setInterval(() => {
      const nextIndex = currentWordIndex + 1;

      // Ensure availableLetters is updated correctly for the next word
      if (letters[nextIndex]) {
        const nextWord = letters[nextIndex];
        setAvailableLetters(nextWord.jumble_word.split("")); // Set available letters based on the next word
        setNewAvailableLetters(nextWord.jumble_word.split("")); // If necessary, update newAvailableLetters
      }

      setSelectedLetters([]);
      setCurrentWordIndex(nextIndex);
      setIsAnswerSubmitted(true); // Mark answer as submitted

      if (!letters[nextIndex]) {
        setGameFinished(true);
      }
      if ([4, 8, 12].includes(nextIndex)) {
        setLetters(letters.slice(4)); // Move to the next set of words after 4 words
        setCurrentWordIndex(0);
      }
    }, 10000);

    return () => clearInterval(timer); // Clean up on component unmount
  }, [letters, currentWordIndex, gameFinished]);

  // Handle letter selection from puzzleWord (bottom)
  const handleLetterClick = (letter) => {
    const letterIndex = availableLetters.indexOf(letter); // Find the index of the first occurrence
    if (letterIndex !== -1) {
      // Add selected letter to the top box
      setSelectedLetters([...selectedLetters, letter]);

      // Remove only the first occurrence of the selected letter
      const updatedAvailableLetters = [...availableLetters];
      updatedAvailableLetters.splice(letterIndex, 1); // Remove the letter by index
      setAvailableLetters(updatedAvailableLetters);
      //   setNewAvailableLetters(updatedAvailableLetters);
    }
  };

  // Undo letter selection by clicking on wordBack
  const handleUndo = () => {
    if (selectedLetters.length > 0) {
      const lastLetter = selectedLetters[selectedLetters.length - 1];
      setSelectedLetters(selectedLetters.slice(0, -1)); // Remove last selected letter
      setAvailableLetters([...availableLetters, lastLetter]); // Add back to available letters
      setNewAvailableLetters([...availableLetters, lastLetter]);
    }
  };

  // Submit button logic
  const handleSubmit = () => {
    const nextIndex = currentWordIndex + 1;

    // Ensure availableLetters is updated correctly for the next word
    if (letters[nextIndex]) {
      const nextWord = letters[nextIndex];
      setAvailableLetters(nextWord.jumble_word.split("")); // Set available letters based on the next word
      setNewAvailableLetters(nextWord.jumble_word.split("")); // If necessary, update newAvailableLetters
    }

    setSelectedLetters([]);
    setCurrentWordIndex(nextIndex);
    setIsAnswerSubmitted(true); // Mark answer as submitted

    const payload = {
      questionId: letters[currentWordIndex]?.id,
      answer: selectedLetters.join(""),
    };

    dispatch(submitScramblerGame({ payload, isTestEnv: true })).then(
      (response) => {
        if (
          response?.meta?.requestStatus === constants.THUNK_STATUS.FULFILLED
        ) {
          if (response?.payload?.isCorrect) {
            setCorrectCount(correctCount + 1);
            setIsCorrectAnswer(true);
          } else {
            setIsCorrectAnswer(false);
          }
        }
      }
    );

    if (nextIndex === 12) {
      setGameFinished(true);
    }
    if ([4, 8, 12].includes(nextIndex)) {
      setLetters(letters.slice(4)); // Move to the next set of words after 4 words
      setCurrentWordIndex(0);
    }
  };

  const resetGame = () => {
    dispatch(getScramblerGame()).then((response) => {
      if (response?.meta?.requestStatus === constants.THUNK_STATUS.FULFILLED) {
        setLetters(response.payload.words);
        setAvailableLetters(
          response.payload.words[currentWordIndex].jumble_word.split("")
        );
        setNewAvailableLetters(
          response.payload.words[currentWordIndex].jumble_word.split("")
        );
        setGameFinished(false);
        setCurrentWordIndex(0);
        setSelectedLetters([]);
        setCorrectCount(0);
      }
    });
  };

  // Get the 4 words to display at a time
  useEffect(() => {
    const wordsToDisplay = letters.slice(0, 4);
    setWordsToDisplay(wordsToDisplay);
  }, [letters]);

  // Display "Game Finished" when all questions are shown
  if (gameFinished) {
    return (
      <div className={styles.gamesWrapper}>
        <div className={styles.gameFinished}>
          <img src="../../images/right_party.gif" alt="party" />
          {correctCount >= 5 && (
            <>
              {/* Render Confetti when the player scores 5 or more */}
              <Confetti width={width} height={height} />
              <h1>Congratulations!</h1>
            </>
          )}
          {correctCount < 5 && <h1>Nice Try!</h1>}

          <p>
            You scored <b>{correctCount}/10</b>!{" "}
            {correctCount < 5 && "Almost a perfect score!"}
            {correctCount >= 5 && <b>You Rock!</b>}
          </p>
          {userDetails?.isNewUser && (
            <p>You earned a free consultation from the Credit Genius</p>
          )}
          <p>
            Now try your hand at another game or get a credit consultation with
            the Credit Genius
          </p>
          <button type="button" className="dfltBtn" onClick={resetGame}>
            <span>PLAY AGAIN</span>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.creditScramblerWrapper}>
        <div className={styles.gamesBoxHeader}>
          <h4>Credit Scrambler</h4>
          <div className={styles.gamesBoxHeaderRight}>
            Question: &nbsp;
            <b>
              <span>2 </span>
              / 10
            </b>
          </div>
        </div>
        <div className={styles.puzzleContainer}>
          <div className={styles.creditPuzzleBox}>
            {wordsToDisplay.length > 0 &&
              (wordsToDisplay || []).map((word, index) => {
                // Skip rendering the word at currentWordIndex
                if (index === currentWordIndex)
                  return (
                    <div className={`${styles.puzzleRow} ${styles.unAnswer}`}>
                      <div className={styles.puzzleWordBox}>
                        <span className={styles.unjumbleText}>
                          Unjumbled Answers: 2 of 4
                        </span>
                      </div>
                      <div className={styles.puzzleAnswerCheck}>
                        <img
                          src={"./images/question_icon.svg"}
                          alt="question_icon"
                        />
                      </div>
                    </div>
                  );

                return (
                  <div className={styles.puzzleRow} key={word?.id}>
                    <div className={styles.puzzleWordBox}>
                      <ul>
                        {word?.jumble_word.split("").map((letter, index) => (
                          <li key={index}>{letter}</li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.puzzleAnswerCheck}>
                      <img
                        src={"./images/question_mark.svg"}
                        alt="question_mark"
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className={styles.timer}>
            <CountdownCircleTimer
              key={`timer-${currentWordIndex}`} // Ensure unique key for each question
              isPlaying={!gameFinished} // Ensure it stops when the game finishes
              duration={10}
              size={80}
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[7, 5, 2, 0]}
              strokeWidth={5}
              onComplete={() => {
                if (!gameFinished) {
                  return { shouldRepeat: false }; // Prevent repeating indefinitely
                }
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>


          {/* Top Box for selected letters */}
          <div className={styles.puzzleContainerBox}>
            <ul>
              {newAvailableLetters.map((_, index) => (
                // Show selected letter if available, otherwise a blank space
                <li key={index}>{selectedLetters[index] || " "}</li>
              ))}
            </ul>
          </div>

          {/* Bottom Box for available letters */}
          <div className={styles.puzzleWord}>
            <ul>
              {availableLetters?.map((letter, index) => (
                <li key={index} onClick={() => handleLetterClick(letter)}>
                  {letter}
                </li>
              ))}
              <li className={styles.wordBack} onClick={handleUndo}>
                <img src={"./images/arrow_downward.svg"} alt="arrow_downward" />
              </li>
            </ul>
          </div>

          {/* Submit Button */}
          <div className={styles.puzzleAction}>
            <button type="button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Scrambler;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest, fetchPostRequest } from "../../utils/network";
import endpoint from "../../utils/endpoint";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  errors: null,
};

export const getHistory = createAsyncThunk(
  "chat/getHistory",
  async ({ payload, isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    const aiEndpoint =
      isTestEnv > 0 ? constants.AI_TEST_ENDPONIT : constants.AI_ENDPONIT;
    try {
      const response = await fetchGetRequest(
        `${aiEndpoint}${endpoint.chat.get}`,
        {
          params: {
            userId: payload?.userId,
            size: payload?.size,
          },
        },
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

export const askQuestion = createAsyncThunk(
  "chat/askQuestion",
  async ({ payload, isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    const aiEndpoint =
      isTestEnv > 0 ? constants.AI_TEST_ENDPONIT : constants.AI_ENDPONIT;
    try {
      const response = await fetchPostRequest(
        `${aiEndpoint}/${endpoint.chat.post}`,
        {
          user_input: payload?.user_input,
          userId: payload?.userId,
          is_premium: payload?.isPremiumUser,
          is_verified: payload?.isVerifiedUser,
        },
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });

    builder.addCase(askQuestion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(askQuestion.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(askQuestion.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });
  },
});
export default reducer;

import axios from "axios";
import constants from "./constants";
import endpoints from "./endpoint";
import {
  resetLoginData,
  storeAccessTokenFromRefreshToken,
} from "../store/reducers/auth";

// Create two different Axios instances
const http = axios.create({
  baseURL: constants.API_BASE_ENDPOINT,
});

const httpTest = axios.create({
  baseURL: constants.API_TEST_BASE_ENDPOINT,
});

let isRefreshing = false;
let failedRequestsQueue = [];

const isTokenExpired = (error) => {
  return error.response?.status === 401;
};

const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedRequestsQueue = [];
};

export const setupInterceptors = (store, httpInstance) => {
  httpInstance.interceptors.request.use((request) => {
    const CreditGeniusAdminAccessToken =
      store.getState().auth?.data?.userToken?.accessToken;

    if (CreditGeniusAdminAccessToken) {
      request.headers.Authorization = "Bearer " + CreditGeniusAdminAccessToken;
    }

    return request;
  });

  httpInstance.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      const originalRequest = error.config;

      // Check if request has already been retried to prevent infinite loops
      if (!originalRequest || originalRequest._retry) {
        return Promise.reject(error);
      }

      // Only handle token expiration
      if (!isTokenExpired(error)) {
        return Promise.reject(error);
      }

      // Check for refresh token
      const refreshToken = store.getState().auth.data?.userToken?.refreshToken;
      if (!refreshToken) {
        store.dispatch(resetLoginData());
        return;
      }

      // Handle concurrent requests during token refresh
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedRequestsQueue.push({ resolve, reject });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return httpInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      // Start token refresh process
      try {
        isRefreshing = true;
        originalRequest._retry = true;

        const response = await axios.patch(
          constants.API_TEST_BASE_ENDPOINT + endpoints.user.renewToken,
          {
            refreshToken: store.getState().auth?.data?.userToken?.refreshToken,
          }
        );
        const newAccessToken = response?.data?.accessToken;

        store.dispatch(storeAccessTokenFromRefreshToken(response.data));

        // Update authorization header
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Process queued requests
        processQueue(null, newAccessToken);

        // Retry original request
        return httpInstance(originalRequest);
      } catch (refreshTokenError) {
        processQueue(refreshTokenError, null);
        store.dispatch(resetLoginData());
      } finally {
        isRefreshing = false;
      }
    }
  );
};

export const initializeInterceptors = (store) => {
  setupInterceptors(store, http);
  setupInterceptors(store, httpTest);
};

const selectHttpInstance = (condition) => {
  return condition > 0 ? httpTest : http;
};
/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchGetRequest = async (
  endpoint,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.get(endpoint, {
      ...extraConfig,
    });
  } catch (err) {
    throw err.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchPostRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  console.log("http", http);
  try {
    return await http.post(endpoint, payload, {
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchMultipartPostRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.post(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchPutRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.put(endpoint, payload, { ...extraConfig });
  } catch (err) {
    throw err.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchMultipartPutRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.put(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchPatchRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.patch(endpoint, payload, { ...extraConfig });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchMultipartPatchRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.patch(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchDeleteRequest = async (
  endpoint,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.delete(endpoint, { ...extraConfig });
  } catch (err) {
    throw err.response;
  }
};

export default {
  user: {
    get: (userId) => `/auth/userToken/${userId}`,
    addToken: "/auth/add-userToken",
    login: "/sso/sso-login",
    getUserDetails: "/auth/me",
    logout: "/auth/logout",
    renewToken: "auth/renew-token",
  },
  profile: {
    get: "/profile",
    update: "user/complete-profile",
  },
  state: {
    get: "/states/list",
  },
  chat: {
    get: "/chat/getChatHistory",
    post: "chat/askCreditGenius",
  },
  triviaGame: {
    get: "/trivia/get-questions",
    submit: "trivia/user/save-response",
  },
  trueOrFalseGame: {
    get: "/trueOrFalse/get-questions",
    submit: "trueOrFalse/user/save-response",
  },
  scramblerGame: {
    get: "/credit-scrambler/get-words",
    submit: "credit-scrambler/user/save-response",
  },
};

const ConfirmationModal = ({
  showModal,
  setShowModal,
  title,
  message,
  setActiveTab,
}) => {
  const onConfirm = () => {
    setShowModal(false);
    setActiveTab("Dashboard");
  };
  return (
    <div className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="information-content">
            <button
              type="button"
              className="modal-close-btn"
              onClick={() => setShowModal(false)}
            >
              <img src={"./images/close_icon.svg"} alt="close" />
            </button>
            <div className="information-img">
              <img src={"./images/advertising_icon.svg"} alt="advertising" />
            </div>
            <h4>{title}</h4>
            <p>{message}</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConfirm}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDeleteRequest,
  fetchGetRequest,
  fetchPatchRequest,
  fetchPostRequest,
} from "../../utils/network";
import endpoint from "../../utils/endpoint";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: {},
  errors: null,
};

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ payload, isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoint.profile.update,
        payload,
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      const message = err?.data.message || "Something went wrong";
      return thunkAPI.rejectWithValue(err.data);
    }
  }
);

export const me = createAsyncThunk("user/me", async (_, thunkAPI) => {
  try {
    const response = await fetchGetRequest(
      endpoint.user.getUserDetails,
      {},
      constants.IS_TEST_ARRAY_URL
    );
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    const validationErrors = error.data.errors.reduce((acc, message) => {
      return {
        ...acc,
        [message.fieldName]: message.fieldError.join(", ").trim(),
      };
    }, {});
    return thunkAPI.rejectWithValue(validationErrors);
  }
});

const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = {};
    });

    builder.addCase(me.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(me.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(me.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = {};
    });
  },
});
export default reducer;

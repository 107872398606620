import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../utils/constants";
import styles from "../personalCredit/personalCredit.module.scss";
import { addToken, getUserToken } from "../../../store/reducers/userToken";

const CreditSimulatorDashboard = ({ userId }) => {
  const [userToken, setUserToken] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((store) => store?.auth?.data?.user);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUserToken({ userId: userId, isTestEnv: constants.IS_TEST_ARRAY_URL })).then(
      (response) => {
        switch (response?.meta?.requestStatus) {
          case constants.THUNK_STATUS.FULFILLED:
            setUserToken(response?.payload?.userToken);
            return;
          default:
            return;
        }
      }
    );
    window.addEventListener("array-event", handleArrayEvent);

    return () => {
      window.removeEventListener("array-event", handleArrayEvent);
    };
  }, [userToken, userId]);

  const handleArrayEvent = (arrayEvent) => {
    const { tagName, event, metadata = {} } = arrayEvent.detail;
    const payload = { userId: userId, userToken: metadata?.["user-token"] };
    if (event === "success" && Object.keys(metadata).length > 0) {
      setUserToken(metadata?.["user-token"]);
      dispatch(
        addToken({
          payload,
          isTestEnv: constants.IS_TEST_ARRAY_URL,
        })
      );
    }
  };

  return (
    <>
      {userToken ? (
        <div className={styles.creditReport}>
          <array-credit-score-simulator
            appKey={process.env.REACT_APP_ARRAY_APP_KEY}
            userToken={userToken}
            sandbox="true"
          ></array-credit-score-simulator>
        </div>
      ) : (
        <div className={styles.personalCreditInfo}>
          <array-authentication-kba
            appKey={process.env.REACT_APP_ARRAY_APP_KEY}
            sandbox="true"
            userId={userId}
            showResultPages="true"
          ></array-authentication-kba>
        </div>
      )}
    </>
  );
};

export default CreditSimulatorDashboard;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import endpoint from "../../utils/endpoint";
import { fetchGetRequest, fetchPostRequest } from "../../utils/network";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errors: null,
};

export const getScramblerGame = createAsyncThunk(
  "ScramblerGame/getScramblerGame",
  async (_, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoint.scramblerGame.get, {}, true);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

export const submitScramblerGame = createAsyncThunk(
  "ScramblerGame/submitScramblerGame",
  async ({ payload, isTestEnv = false }, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoint.scramblerGame.submit,
        payload,
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "ScramblerGame",
  initialState,
  reducers: {
    resetScramblerGame: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.data = null;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScramblerGame.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScramblerGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getScramblerGame.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });

    builder.addCase(submitScramblerGame.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitScramblerGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    //   state.data = action.payload;
    });
    builder.addCase(submitScramblerGame.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
    //   state.data = [];
    });
  },
});

export default reducer;
export const { resetScramblerGame } = actions;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest } from "../../utils/network";
import endpoint from "../../utils/endpoint";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  errors: null,
};

export const getStates = createAsyncThunk(
  "states/getStates",
  async (_, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoint.state.get, {}, constants.IS_TEST_ARRAY_URL);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "states",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getStates.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });
  },
});
export default reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import endpoint from "../../utils/endpoint";
import { fetchGetRequest, fetchPostRequest } from "../../utils/network";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errors: null,
};

export const getTriviaGame = createAsyncThunk(
  "triviaGame/getTriviaGame",
  async (_, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoint.triviaGame.get, {}, constants.IS_TEST_ARRAY_URL);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

export const submitTriviaGame = createAsyncThunk(
  "triviaGame/submitTriviaGame",
  async ({ payload, isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoint.triviaGame.submit,
        payload,
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "triviaGame",
  initialState,
  reducers: {
    resetTriviaGame: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.data = null;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTriviaGame.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTriviaGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getTriviaGame.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });

    builder.addCase(submitTriviaGame.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitTriviaGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    //   state.data = action.payload;
    });
    builder.addCase(submitTriviaGame.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
    //   state.data = [];
    });
  },
});

export default reducer;
export const { resetTriviaGame } = actions;

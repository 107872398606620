import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import endpoint from "../../utils/endpoint";
import { fetchGetRequest, fetchPostRequest } from "../../utils/network";
import constants from "../../utils/constants";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: null,
  errors: null,
};

export const getTrueOrFalseGame = createAsyncThunk(
  "trueOrFalseGame/getTrueOrFalseGame",
  async (_, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoint.trueOrFalseGame.get, {}, constants.IS_TEST_ARRAY_URL);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

export const submitTrueOrFalseGame = createAsyncThunk(
  "trueOrFalseGame/submitTrueOrFalseGame",
  async ({ payload, isTestEnv = constants.IS_TEST_ARRAY_URL }, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoint.trueOrFalseGame.submit,
        payload,
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "trueOrFalseGame",
  initialState,
  reducers: {
    resetTrueOrFalseGame: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.data = null;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrueOrFalseGame.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTrueOrFalseGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getTrueOrFalseGame.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });

    builder.addCase(submitTrueOrFalseGame.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitTrueOrFalseGame.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    });
    builder.addCase(submitTrueOrFalseGame.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
    });
  },
});

export default reducer;
export const { resetTrueOrFalseGame } = actions;

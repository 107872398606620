import styles from "./creditGames.module.scss";
const CreditGames = ({ handleClick }) => {
  return (
    <div className={styles.creditGames}>
      <div className="row">
        <div className="col-md-4">
          <div className={styles.gamesBox}>
            <div className={styles.gamesImg}>
              <img src={"./images/credit_games.svg"} alt="trivia" />
            </div>
            <h4>Trivia</h4>
            <div
              className={styles.gameIcon}
              onClick={() => handleClick("trivia")}
            >
              <img
                src={"./images/right_arrow_icon.svg"}
                alt="right_arrow_icon"
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className={styles.gamesBox}>
            <div className={styles.gamesImg}>
              <img src={"./images/credit_games.svg"} alt="true_False" />
            </div>
            <h4>True Or False</h4>
            <div
              className={styles.gameIcon}
              onClick={() => handleClick("trueOrFalse")}
            >
              <img
                src={"./images/right_arrow_icon.svg"}
                alt="right_arrow_icon"
              />
            </div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className={styles.gamesBox}>
            <div className={styles.gamesImg}>
              <img src={"./images/credit_games.svg"} alt="credit_Scrambler" />
            </div>
            <h4>Credit Scrambler</h4>
            <div className={styles.gameIcon}
              onClick={() => handleClick("scrambler")}>
              <img
                src={"./images/right_arrow_icon.svg"}
                alt="right_arrow_icon"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CreditGames;
